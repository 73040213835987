import { UserService } from "@/services/user.service";
import UserStorage from "@/services/userstorage.service.js";
import TokenService from "@/services/token.service";

const state = {
  accessToken: TokenService.getToken(),
  firstName: UserStorage.getFirstName(),
  lastName: UserStorage.getLastName(),
  accessLevel: UserStorage.getAccessLevel(),
  autoLoggedOut: false,
  isSuperuser: UserStorage.getIsSuperuser(),
};

const getters = {
  loggedIn: (state) => {
    return state.accessToken ? true : false;
  },
  isAutoLoggedOut: (state) => {
    return state.autoLoggedOut;
  },
  isOwnerOrManager: (state) => {
    return ["owner", "manager"].includes(state.accessLevel);
  },
  isSuperuser: (state) => {
    return state.isSuperuser;
  },
};

const actions = {
  async updateLoginData(
    { commit },
    { token, 
      first_name, 
      last_name, 
      access_level, 
      subscription_active,
      is_superuser
    }
  ) {
    commit("setToken", token);
    commit("setFirstName", first_name);
    commit("setLastName", last_name);
    commit("setAccessLevel", access_level);
    commit("setIsSuperuser", is_superuser);
  },
  async updateAutoLoggedOut({ commit }, { autologgedout }) {
    commit("setAutoLoggedOut", autologgedout);
  },
  async logout({ commit }, { autologout, logoutFromAllDevices }) {
    await UserService.logout(logoutFromAllDevices);
    if (autologout === true) {
      commit("setAutoLoggedOut", true);
    }
    commit("setFirstName", null);
    commit("setLastName", null);
  },
};

const mutations = {
  setToken(state, token) {
    state.accessToken = token;
    TokenService.saveToken(token);
  },
  setFirstName(state, firstName) {
    state.firstName = firstName;
    UserStorage.saveFirstName(firstName);
  },
  setLastName(state, lastName) {
    state.lastName = lastName;
    UserStorage.saveLastName(lastName);
  },
  setAccessLevel(state, accessLevel) {
    state.accessLevel = accessLevel;
    UserStorage.saveAccessLevel(accessLevel);
  },
  setAutoLoggedOut(state, autologgedout) {
    state.autoLoggedOut = autologgedout;
  },
  setIsSuperuser(state, value) {
    state.isSuperuser = value;
    UserStorage.saveIsSuperuser(value);
  },
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default auth;
